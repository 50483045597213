import { render, staticRenderFns } from "./AuthorPicker.vue?vue&type=template&id=baba8124&scoped=true"
import script from "./AuthorPicker.vue?vue&type=script&lang=js"
export * from "./AuthorPicker.vue?vue&type=script&lang=js"
import style0 from "./AuthorPicker.vue?vue&type=style&index=0&id=baba8124&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baba8124",
  null
  
)

export default component.exports